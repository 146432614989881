import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout' 
import Widgets from '../components/widgets/Widgets' 
import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function Careers({ data }) {
  const page = data.allWpPage.nodes[0];
  const [widgets, setWidgets] = useState(page.widgets?.pageWidgets);
  const jobsref = useRef();

  const scrollToJobs = function() {
	if (window && jobsref.current) {
	  window.scrollTo({
		top: jobsref.current.offsetTop,
		behavior: 'smooth',
	  });
    }
  }

  useEffect(() => {
	
    //inject the careers widget after the 2nd widget. It only appears on this page.
    if (widgets && widgets.length>1 && !widgets.some(obj => obj.__typename==='careers') ) {
	    var widget = {
			__typename: 'careers',
			title: page.title,
			content: page.content,
			ref: jobsref
		};
		widgets.splice(3, 0, widget);
		
		//if we have some tabs, inject after 5th widget
		if (page.careers?.careersTabsTitle && page.careers?.careersTabs?.length) {
			widget = page.careers;
			widget.__typename = 'careersTabs';
			
			//loop through all the tabs
			page.careers.careersTabs.forEach(function(tab) {
				if (tab.copy?.button?.url==='#careers') {
					tab.copy.button.onClick = scrollToJobs;
				}
			});
			
			widgets.splice(5, 0, widget);
		}
		
		if (widgets[0].__typename==="WpPage_Widgets_PageWidgets_Hero" && widgets[0].buttons?.length>0) {
			widgets[0].buttons[0].button.onClick = scrollToJobs;
		}
		
		setWidgets([ ...widgets ]);
    }
  
    //console.log(widgets);
  }, [widgets, page, jobsref]);

  return (
    <Layout className="page-careers">	
      <Seo post={page} />
	  <article>
		<Widgets widgets={widgets} page={page.slug} />
	  </article>
    </Layout>
  )
}
export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title	    
		slug
		content
		...WidgetFragment
        ...PageSEOFragment
		careers {		  
          careersTabsTitle
	      careersTabs {
	        tabTitle
	        image {
	          localFile {
			    childImageSharp {
		          gatsbyImageData(
			        sizes: "(min-width: 768px) 476px, calc(100vw - 32px)",
				    breakpoints: [622, 952]
		          )
		        }
              }
			  altText
	        }
	        copy {
	          quoteText
	          quoteSourceName
	          quoteSourceCompany
	          copy
	          button {
	            target
	            title
	            url
	          }
	        }
	      }
	    }
      }
    }
  }
`